import React from "react";

const SriLanka = () => {
  return (
    <>
      <h3 className="text-center">Sri Lanka</h3>
      <h4 className="text-center mt-4">Under Construction</h4>
    </>
  );
};

export default SriLanka;
