import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Link } from 'react-router-dom';
import { ReligioUrl } from './API/Api';

const Scrollbar = () => {
  const [scrollbar, setScrollbar] = useState(null);

  const scrollbarNews = async () => {
    try {
      const res = await axios.get(
        `${ReligioUrl}/upcoming/calendar/events/province/1`
      );
      const currentDate = moment();
      const filteredAndSortedNews = res?.data?.data
        .filter((item) => {
          const newsDate = moment(item?.start_date, "DD - MMMM");
          return newsDate?.isSameOrAfter(currentDate, "day");
        })
        .sort((a, b) => {
          const dateA = moment(a?.start_date, "DD - MMMM");
          const dateB = moment(b?.start_date, "DD - MMMM");
          return dateA - dateB;
        });
      setScrollbar(filteredAndSortedNews?.slice(0, 1));  // Slice to get the first upcoming event
    } catch (error) {
      console.error("Error Fetching News:", error);
    }
  };

  useEffect(() => {
    scrollbarNews();
  }, []);

  const [isScrollingAllowed, setIsScrollingAllowed] = useState(true);

  const stopScroll = () => {
    setIsScrollingAllowed(false);
  };

  const allowScroll = () => {
    setIsScrollingAllowed(true);
  };

  return (
    <div className="scrollbar">
      <div className="container">
        <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
          <div className="col-4 col-lg-2 d-flex">
            <div className="label ripple">Flash News</div>
          </div>
          <div className="col-8 col-lg-10 d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
            <div className="marqueenews">
              <div className="marquee">
                <p
                  onMouseEnter={stopScroll}
                  onMouseLeave={allowScroll}
                  onTouchStart={stopScroll}
                  onTouchEnd={allowScroll}
                  style={{ overflow: isScrollingAllowed ? "" : "hidden" }}
                >
                  {scrollbar?.map((newsItem, index) => (
                    <span key={index}>
                      <img
                        src="images/logos/output-onlinegiftools.gif"
                        style={{
                          maxWidth: "40px",
                        }}
                        alt=""
                      />
                      <Link
                        to={`/all-latest-news?newsid=${encodeURIComponent(
                          btoa(newsItem?.id)
                        )}`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {newsItem?.name}
                      </Link>
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scrollbar;
