import React from "react";

const Pastoral = () => {
  return (
    <>
      <div className="container">
        <h3 className="text-center">Pastoral</h3>
      </div>
    </>
  );
};

export default Pastoral;
