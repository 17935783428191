import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const AllHomeNews = () => {
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
        axios.get('https://app.lasalleindia.org/api/upcoming/calendar/events/province/1')
            .then(response => {
                const sortedNews = response?.data?.data?.sort((a, b) => {
                    const dateA = new Date(a?.start_date?.split('-')?.reverse()?.join('-'));
                    const dateB = new Date(b?.start_date?.split('-')?.reverse()?.join('-'));
                    return dateA - dateB;
                });
                setNewsData(sortedNews);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div className="accordion container" id="newsAccordion">
            <h3 className='text-center'>Latest News</h3>
            {newsData?.map((newsItem, index) => (
                <div className="accordion-item" key={newsItem?.id}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls={`collapse${index}`}
                        >
                           <i className='fa fa-list mr-2'></i> {newsItem?.name}
                        </button>
                    </h2>
                    <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#newsAccordion"
                    >
                        <div className="accordion-body">
                            <p><strong>Date:</strong> {newsItem?.start_date}</p>
                            <p><strong>Type:</strong> {newsItem?.type}</p>
                            <p><strong>Description:</strong></p>
                            <div dangerouslySetInnerHTML={{ __html: newsItem?.description_html }} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AllHomeNews;
